import React from 'react';
import { UniqueFormStep } from '../StyledComponents';

const Step4 = () => (
  <UniqueFormStep>
    <h2>Your payment is being processed</h2>
    <p>Thank you! Your payment is currently being processed. You will receive a confirmation shortly.</p>
  </UniqueFormStep>
);

export default Step4;